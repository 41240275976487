@tailwind base;
@tailwind components;
@tailwind utilities;
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}
@layer base {
  @font-face {
    font-family: "zentry";
    src: url("assets/fonts/zentry-regular.woff2") format("woff2");
  }
  :root {
    --background: 0 0% 100%;
    --foreground: 222.2 84% 4.9%;
    --card: 0 0% 100%;
    --card-foreground: 222.2 84% 4.9%;
    --popover: 0 0% 100%;
    --popover-foreground: 222.2 84% 4.9%;
    --primary: 222.2 47.4% 11.2%;
    --primary-foreground: 210 40% 98%;
    --secondary: 210 40% 96.1%;
    --secondary-foreground: 222.2 47.4% 11.2%;
    --muted: 210 40% 96.1%;
    --muted-foreground: 215.4 16.3% 46.9%;
    --accent: 210 40% 96.1%;
    --accent-foreground: 222.2 47.4% 11.2%;
    --destructive: 0 84.2% 60.2%;
    --destructive-foreground: 210 40% 98%;
    --border: 214.3 31.8% 91.4%;
    --input: 214.3 31.8% 91.4%;
    --ring: 222.2 84% 4.9%;
    --chart-1: 12 76% 61%;
    --chart-2: 173 58% 39%;
    --chart-3: 197 37% 24%;
    --chart-4: 43 74% 66%;
    --chart-5: 27 87% 67%;
    --radius: 0.5rem;
  }
  .dark {
    --background: 222.2 84% 4.9%;
    --foreground: 210 40% 98%;
    --card: 222.2 84% 4.9%;
    --card-foreground: 210 40% 98%;
    --popover: 222.2 84% 4.9%;
    --popover-foreground: 210 40% 98%;
    --primary: 210 40% 98%;
    --primary-foreground: 222.2 47.4% 11.2%;
    --secondary: 217.2 32.6% 17.5%;
    --secondary-foreground: 210 40% 98%;
    --muted: 217.2 32.6% 17.5%;
    --muted-foreground: 215 20.2% 65.1%;
    --accent: 217.2 32.6% 17.5%;
    --accent-foreground: 210 40% 98%;
    --destructive: 0 62.8% 30.6%;
    --destructive-foreground: 210 40% 98%;
    --border: 217.2 32.6% 17.5%;
    --input: 217.2 32.6% 17.5%;
    --ring: 212.7 26.8% 83.9%;
    --chart-1: 220 70% 50%;
    --chart-2: 160 60% 45%;
    --chart-3: 30 80% 55%;
    --chart-4: 280 65% 60%;
    --chart-5: 340 75% 55%;
  }
}

.special-font b {
  font-family: "Zentry";
  font-feature-settings: "ss01" on;
}
.code {
  position: relative;
  margin: 12px 6px 0px 6px;
  max-width: 500px;
}
.code .hidden {
  display: none;
}
.code-language {
  background-color: #2f2f2f;
  padding: 12px;
  border-radius: 10px 10px 0 0;
  color: white;
  text-transform: uppercase;
}
.force-show {
  display: block !important;
}
.code-block {
  background-color: #0d0d0d;
  padding: 12px;
  border-radius: 0 0 10px 10px;
  color: white;
}


.code-img:not(.hidden) + .code-block:not(.force-show) {
  display: none;
}
.code-img {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  background: linear-gradient(to bottom right, #2f2f2f, #77b0aa);
  min-height: 96px;
  overflow: hidden;
}
.code-img:not(.force-show) {
  border-radius: 0 0 10px 10px;
}

.code-img > img {
  display: block;
  max-width: 100%;
  max-height: 100%;
}

.code-options {
  position: absolute;
  display: flex;
  flex-flow: row nowrap;
  top: 11px;
  right: 11px;
  gap: 11px;
  color: white;
  text-transform: uppercase;
}
.seeCode:not(.hidden) + .seeImage {
  display: none;
}
.about-title {
  clip-path: polygon(0 0, 100% 1%, 100% 100%, 0% 100%);
}

@keyframes flashGreen {
  0% {
    background-color: #00ff00;
    color: black;
  }
  100% {
    background-color: #4a4a4a;
    color: white;
  }
}

@layer base {
  * {
    @apply border-border;
  }
  body {
    @apply bg-background text-foreground;
  }
}
